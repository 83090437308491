                      $base-color: #444444;                      $secondary-color: #d00200;                      $font-normal: Taz;                      $font-bold: Taz;                    
.gallery{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: transparentize(#EEE, 0.03);

    button, a {
        width: 49px;
        height: 49px;
        border: 0;
        border-radius: 0%;
        cursor: pointer;
        background-color: #B80B00;
        margin: 4px;

        &::before, &::after{
            border-color: mix(saturate($base-color, 50%), #fff, $weight: 20%);
        }

        &:focus{
            outline: none;
        }

        &:disabled{
            opacity: 0.25;
        }
    }

    .close{
        position: absolute;
        top: 50px;
        right: 50px;

        &::before, &::after{
            content: '';
            position: absolute;
            border-style: solid;
            border-width: 2px;
            width: 12px;
            height: 12px;
            transform-origin: 1px 1px;
            border-bottom-width: 0;
            border-right-width: 0;
            top: 24px;
            left: 24px;
        }

        &::before{
            transform: rotate(135deg);
        }

        &::after{
            transform: rotate(-45deg);
        }
    }

    .gallery-container{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-content: stretch;

        .gallery-top{
            flex-basis: 20px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-end;
            max-width: 1199px;
            width: 100%;
            margin: auto;

            &>div{
                flex-grow: 0;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                margin-bottom: 30px;
                font-size: 16px;

                &.galleries{
                    font-size: 22px;
                }
    
                .dot{
                    font-size: 24px;
                    opacity: 0.4;
                    margin: 0 26px;
                    font-weight: 700;
                    padding-bottom: 10px;
                    cursor: pointer;
                    white-space: nowrap;
    
                    &.current{
                        opacity: 1;
                        border-bottom: 2px solid #B80B00;
                    }
                }
            }
        }

        .player{
            flex-grow: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .player-left{
                flex-basis: 20px;
                flex-grow: 0;
                padding-right: 40px;

                button{
                    position: relative;
                    &::before{
                        content: '';
                        position: absolute;
                        border-style: solid;
                        border-width: 2px;
                        width: 12px;
                        height: 12px;
                        transform-origin: 1px 1px;
                        border-bottom-width: 0;
                        border-right-width: 0;
                        top: 24px;
                        left: 16px;
                        transform: rotate(-45deg);
                    }
                }
            }
            .player-right{
                flex-basis: 20px;
                flex-grow: 0;
                padding-left: 40px;

                button{
                    position: relative;
                    &::before{
                        content: '';
                        position: absolute;
                        border-style: solid;
                        border-width: 2px;
                        width: 12px;
                        height: 12px;
                        transform-origin: 1px 1px;
                        border-bottom-width: 0;
                        border-right-width: 0;
                        top: 24px;
                        left: 28px;
                        transform: rotate(135deg);
                    }
                }
            }
            .player-center{
                flex-basis: 1199px;
                flex-grow: 0;

                >div{
                    width: 100%;
                    padding-top: 67%;
                    position: relative;
                    box-shadow: 8px 8px 20px #00000026;

                    .slider {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .gallery-bottom{
            flex-basis: 20px;
            flex-grow: 1;
            flex-shrink: 1;
        }
    }
}

.fade-enter{
    opacity: 0;
}
.fade-enter-active{
    opacity: 1;
    transition: opacity 500ms linear;
}
.fade-exit{
    opacity: 1;
}
.fade-exit-active{
    opacity: 0.01;
    transition: opacity 500ms linear ;
}
.fade-exit-done{
    opacity: 0;
}
.fade-appear{
    opacity: 0;
}
.fade-appear-active{
    opacity: 1;
    transition: opacity 500ms linear ;
}

@media only screen and (max-width: 1377px) {
    .gallery{
    
        .gallery-container{
            .gallery-top{
                width: auto;
                margin-left: 89px;
                margin-right: 89px;
            }
        }
    }
}

@media only screen and (max-width: 730px) {
    .gallery{
    
        .gallery-container{
    
            .gallery-top{
                &>div{
                    font-size: 10px;
    
                    &.galleries{
                        font-size: 15px;
                    }
                    
                    .dot{
                        margin: 0 1px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 540px) {
    .gallery{
    
        .gallery-container{
    
            .gallery-top{
                margin-left: 59px;
                margin-right: 59px;

                &>div{
                    font-size: 8px;
    
                    &.galleries{
                        font-size: 11px;
                    }
                    
                    .dot{
                        margin: 0 1px;
                    }
                }
            }
            .player{

                .player-left{
                    padding-right: 10px;
                }
                .player-right{
                    padding-left: 10px;
                }
            }
        }
    }
}