                      $base-color: #444444;                      $secondary-color: #d00200;                      $font-normal: Taz;                      $font-bold: Taz;                    
body {
  margin: 0;
  // font-family: $font-normal, taz;
  font-family: Taz;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $base-color;
}

h1, h2{
  font-family: Taz;
  line-height: 1;
}

* {
	box-sizing: border-box;
}
