                      $base-color: #444444;                      $secondary-color: #d00200;                      $font-normal: Taz;                      $font-bold: Taz;                    
.section1{
  padding: 130px 0 110px 0;
  // background-color: transparentize($color: $base-color, $amount: 0.94);
  background-color: #dddddd;
  color: $base-color;
  overflow-x: hidden;

  .section-content{
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: flex-start;

    .section-content-left{
      flex-basis: 476px;
      flex-shrink: 1;
      margin: 0;
      margin-right: 238px;
      
      h3{
        font-size: 24px;
        font-weight: 700;
      }
      
      p{
        font-size: 17px;
        margin: 0 0 20px 0;
        line-height: 24px;
      }
    }



    .section-content-right{
      flex-basis: 486px;
      font-size: 18px;
      line-height: 26px;
      flex-shrink: 1;
      position: relative;

      &::before, &::after{
        content: ' ';
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        display: block;
      }

      &::before{
        transform: translate(-80px, -80px);
        //background-color: transparentize($color:  saturate($base-color, 50%), $amount: 0.80);
        background-color: #CC0000;
      }

      &::after{
        transform: translate(205px, 80px);
        //background-color: transparentize($color: saturate($base-color, 50%), $amount: 0.35);
        background-color: #B80B00;
      }

      img{
        width: 100%;
        z-index: 10;
        position: relative;
      }
    }
  }
}

.section2{
  background-color: transparentize($color: $base-color, $amount: 0.94);
  padding: 0 0 80px 0;
  font-size: 18px;
  
  background-image: url('./Assets/eye.svg');
  background-repeat: no-repeat;
  background-position: center bottom -250px;
  background-size: 788px;

  .section-content{
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;

    .section-content-left{
      flex-basis: 591px;
      margin-right: 100px;
      flex-shrink: 1;

      svg{
        width: 100%;
        height: 63%;
      }
    }

    .section-content-right{
      flex-basis: 510px;
      flex-shrink: 1;
      position: relative;

      h2{
        font-size: 24px;
        font-weight: 900;
        line-height: 26px;
      }

      p{
        font-size: 17px;
        line-height: 26px;
        font-weight: 400;
      }
    }
  }
}

.section-options{
  padding-top: 90px;

  
  .section-options-header{
    text-align: center;
    max-width: 817px;
    margin: auto;

    h2{
      font-size: 50px;
      font-weight: 900;
    }
    p{
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 100px;
      font-weight: bold;
    }
  }
}

.section3{
  padding: 90px 0 90px 0;
  background-color: transparentize($color: $base-color, $amount: 0.94);
  color: $base-color;
  text-align: center;

  h2{
    font-size: 35px;
    font-weight: 900;
    margin-top: 0;
  }

  a{
    display: inline-block;
    padding: 30px 60px;
    background-color: $secondary-color;
    color: #fff;
    box-shadow: 8px 8px 20px #00000026;
    border-radius: 10px;
    font-size: 22px;
    font-weight: 900;
    text-decoration: none;
  }
}

@media only screen and (max-width: 1280px) {
  .section1{
    padding: 75px 20px 50px 20px;

    .section-content{
      .section-content-left{
        margin-right: 100px;
      }
    }
  }

  .section2{
    padding: 75px 0;

    .section-content{
      max-width: 1000px;
      padding: 0 20px;
    }
  }

  .section3{
    padding: 50px 0;
  }
}

@media only screen and (max-width: 768px) {
  .section1{
    padding: 0 20px 50px 20px;
  
    .section-content{
      flex-direction: column;
  
      .section-content-left{
        margin-right: 0;
        flex-basis: auto;
        margin-bottom: 80px;
      }
  
      .section-content-right{
        flex-basis: auto;
        font-size: 18px;
        

        &::before, &::after{
          display: none;
        }
  
        p{
          margin: 0 0 20px 0;
        }
      }
    }
  }
  .section2{
    padding: 25px 0;
    background-position: center bottom -125px;
    background-size: 394px;
  
    .section-content{
      flex-direction: column;
      align-items: stretch;
  
      .section-content-left{
        margin-right: 0;
        flex-basis: auto;
        margin-bottom: 20px;
      }
  
      .section-content-right{
        flex-basis: auto;
        font-size: 18px;
        padding-left: 0;

        h5{
          padding: 0 0 0 58px;
          min-height: 50px;
        }
      }
    }
  }
  

  .section-options{
    padding-top: 25px;

    .section-options-header{
      padding: 0 20px;
      h2{
        font-size: 26px;
      }
      p{
        font-weight: normal;
        font-size: 14px;
        margin-bottom: 50px;
      }
    }
  }

  

  .section3{
    padding: 25px 0;

    h2{
      font-size: 20px;
      font-weight: 900;
    }

    a{
      padding: 20px 20px;
      border-radius: 10px;
      font-size: 15px;
      font-weight: 700;
      text-decoration: none;
    }
  }
}
