                      $base-color: #444444;                      $secondary-color: #d00200;                      $font-normal: Taz;                      $font-bold: Taz;                    
header {
    height: 780px;
    background-color: #fff;
    margin-bottom: 8px;
    color: $base-color;
    background-image: url('./Assets/eye.svg');
    background-repeat: no-repeat;
    background-position: right 103px top 91px;

    .logo{
        width: 120px;
        margin: 20px 0 100px 28px;
    }

    .header {
        position: relative;
        max-width: 1200px;
        margin: auto;

        h1{
            margin: 0;
            padding: 0;
            font-size: 145px;
            margin-left: 600px;
            font-weight: 900;
            color: #444444;
        }

        h2{
            margin: 0;
            padding: 0;
            font-size: 145px;
            margin-top: -20px;
            margin-left: 625px;
            font-weight: 900;
            text-align: right;
            color: #d00200;
        }

        h3{
            margin: 0;
            padding: 0;
            font-size: 141px;
            margin-top: -40px;
            min-height: 30px;
            margin-left: 750px;
            color: #000;
            opacity: 0.1;
        }

        h4{
            text-align: right;
            font-size: 28px;
            background-color: $secondary-color;
            color: #fff;
            padding: 14px 28px 14px 180px;
            margin-left: 220px;
            font-weight: lighter;
            font-style: italic;
        }

        h2, h3{
            &.outline{
                opacity: 1;
                color: #fff;
                text-shadow: -2px 2px 0 $base-color, 2px 2px 0 $base-color, 2px -2px 0 $base-color, -2px -2px 0 $base-color;
            }
        }

        &.small{
            h1, h2, h3{
                font-size: 110px;
                font-weight: 900;
            }
        }

        .ipad{
            position: absolute;
            top: -50px;
            left: -380px;
            width: 1173px;
            height: 822px;
    
            background-image: url('./Assets/iPad-fritlagt.png');
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            transform-origin: 0px 0px 0px;

            video{
                width: 100%; 
                transform: matrix3d(0.59622, -0.116053, 0, 0.000132, 
                    0.271423, 0.488863, 0, -0.000082, 
                    0, 0, 1, 0, 
                    245, 162, 0, 1);
                transform-origin: 0px 0px 0px;
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    header{
        height: 620px;

        .header{
            max-width: 1024px;

            h1{
                font-size: 105px;
                margin-left: 595px;
            }
    
            h2{
                font-size: 105px;
                margin-top: -10px;
                margin-left: 662px;
            }
    
            h3{
                font-size: 105px;
                margin-top: -30px;
                margin-left: 690px;
            }
    
            h4{
                font-size: 21px;
                padding: 14px 28px 14px 80px;
            }

            &.small{
                h1, h2, h3{
                    font-size: 90px;
                }
            }

            .ipad{
                left: -160px;
                transform: scale(0.75);
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    header{
        height: 500px;

        .header{
            max-width: 768px;

            h1{
                font-size: 70px;
                margin-left: 470px;
            }
    
            h2{
                font-size: 70px;
                margin-top: -10px;
                margin-left: 515px;
            }
    
            h3{
                font-size: 70px;
                margin-top: -20px;
                margin-left: 550px;
            }
    
            h4{
                font-size: 14px;
                padding: 14px 28px 14px 100px;
            }

            &.small{
                h1, h2, h3{
                    font-size: 52px;
                }
            }


            .ipad{
                left: -60px;
                transform: scale(0.5);
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    header{
        height: 555px;

        .logo{
            width: 72px;
            margin: 30px 0 0 30px;
        }

        .header{
            text-align: left;
            padding: 30px;
            padding-right: 0;
            width: 100%;

            h1{
                font-size: 70px;
                margin-left: 0;
            }
    
            h2{
                font-size: 70px;
                margin-top: -10px;
                margin-left: 0;
                text-align: left;
            }
    
            h3{
                font-size: 70px;
                margin-top: -20px;
                margin-left: 0;
            }
    
            h4{
                text-align: left;
                margin: 0;
                font-size: 14px;
                padding: 14px;
                width: 100%;
            }

            .ipad{
                left: -50px;
                top: 175px;
                transform: scale(0.5);
            }
        }
    }
}

@media only screen and (max-width: 540px) {
    header{
        height: 565px;
        overflow: hidden;
        margin-bottom: 0;

        .header{
            .ipad{
                left: -50px;
                top: 185px;
                transform: scale(0.4);
            }
        }
    }
}