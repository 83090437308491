                      $base-color: #444444;                      $secondary-color: #d00200;                      $font-normal: Taz;                      $font-bold: Taz;                    
.option{
    max-width: 1200px;
    margin: auto;
    position: relative;
    display: flex;
    margin-bottom: 40px;
    align-items: center;

    .option-header {
        flex-basis: 558px;
        margin-right: 55px;
        flex-shrink: 1;
        
        h4{
            margin: 0;
            font-size: 24px;
            margin-bottom: 30px;
            font-weight: 900;

            span{
                display: inline-block;
                margin-right: 16px;
                font-weight: 700;

                &::after{
                    content: '';
                    margin-left: 16px;
                    width: 2px;
                    height: 23px;
                    background-color: $base-color;
                    display: inline-block;  
                }
            }
        }

        p {
            font-size: 18px;
            line-height: 26px;
            margin-right: 20px;
        }
    }

    .option-content{
        flex-basis: 587px;
        flex-grow: 1;
        flex-shrink: 0;
        position: relative;
        text-align: right;

        img, video{
            box-shadow: 8px 8px 20px #00000026;
            border-radius: 6px;
            width: 100%;
            height: auto;
            cursor: pointer;

            &.overlay{
                box-shadow: none;
                position: absolute;
                bottom: 4px;
                width: auto;
                right: 20px;
            }
        }

        .tryout {
            border: 0;
            background: transparent;
            cursor: pointer;
            color: $secondary-color;
            padding-right: 30px;
            position: relative;
            height: 22px;
            display: inline-block;
            margin-top: 17px;
            font-size: 16px;
            font-weight: 700;
            text-decoration: none;

            &:hover{
                text-decoration: underline;
            }

            &::before{
                right:0;
                top: 0;
                position: absolute;
                content: '';
                display: inline-block;
                width: 21px;
                height: 21px;
                border-radius: 100%;
                border: 1px solid $secondary-color;
                vertical-align: middle;
            }

            &::after{
                content: '';
                right:9px;
                top: 8px;
                position: absolute;
                border-top: 2px solid $secondary-color;
                border-right: 2px solid $secondary-color;
                width: 6px;
                height: 6px;
                transform: rotate(45deg);
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .option{
        .option-content{
            flex-basis: 313px;

            img.overlay{
                box-shadow: none;
                position: absolute;
                bottom: 4px;
                right: 20px;
                width: auto;
                max-height: 110%;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .option{
        display: block;
    
        .option-header {
            flex-basis: 0;
            margin: 0 0 30px 0;
            padding: 60px 30px 0 30px;

            h4{
                font-size: 18px;

                span{
                    margin-right: 10px;
                    &::after{
                        height: 14px;
                        margin-left: 10px;
                    }
                }
            }
        }
    
        .option-content{
            padding: 0 30px;
        }
    }
}
