                      $base-color: #444444;                      $secondary-color: #d00200;                      $font-normal: Taz;                      $font-bold: Taz;                    
footer{
    text-align: center;
    padding: 100px 0;
    
    box-shadow: inset 0px 11px 50px -10px #CCC; 

    .logo{
        width: 200px;
    }

    a {
        font-size: 14px;
        color: #122D3A;
        text-transform: uppercase;
        opacity: 0.5;
        text-decoration: none;
    }
}