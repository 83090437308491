                      $base-color: #444444;                      $secondary-color: #d00200;                      $font-normal: Taz;                      $font-bold: Taz;                    
.category {
    position: relative;
    padding: 0 30px 100px 30px;

    &.first{
        h3{
            max-width: 1200px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &.second {
        background-color: transparentize($color: $base-color, $amount: 0.98);
    }

    &.last {
        .option{
            margin-bottom: 88px;
        }
    }

    h3{
        color: $base-color;
        font-size: 24px;
        text-transform: uppercase;
        text-align: center;
        margin: 0 -30px 100px -30px;
        overflow: hidden;
        transform: translateY(-13px);

        span{
            display: inline-block;
            position: relative;
            
            &::before, &::after {
                content: "";
                position: absolute;
                height: 5px;
                border-bottom: 2px solid $base-color;
                top: 8px;
                width: 1000px;
            }

            &::before {
                right: 100%;
                margin-right: 50px;
            }
            &::after {
                left: 100%;
                margin-left: 50px;
            }
        }
    }
}

@media only screen and (max-width: 1440px) {
    .category {
    }
}

@media only screen and (max-width: 768px) {
    .category {
        padding: 50px 0;

        h3{
            margin: 0 0 100px 0;
        }
    }
}
