                      $base-color: #444444;                      $secondary-color: #d00200;                      $font-normal: Taz;                      $font-bold: Taz;                    
.slider{
    position: relative;
    cursor: ew-resize;

    .before, .after{
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        background-size: cover;
    }    

    .handle{
        position: absolute;
        border-right: 1px solid #fff;
        top:0;
        bottom:0;

        &::after{
            content: '';
            position: absolute;
            top: 50%;
            right: -1em;
            width: 143px;
            height: 82px;
            background-image: url('./Assets/Drag knap.svg');
            transform: translate(55px, -50px);
        }
    }
}